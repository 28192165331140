import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next"; // 导入useTranslation
import "./MainContainer.css";
import Creatable from "react-select/creatable";
import JSZip from "jszip";
import { Radio, Input } from "antd";
import addLocalImg from "./images/select_img_file.png";
import logoImg from "./images/logo.svg";
import Select from "react-select";
import { saveAs } from "file-saver";
import { useNavigate, useParams } from "react-router-dom";
import FAQ from "./FAQ";

function MainContainer() {
  const { lang } = useParams(); // 添加这一行
  const navigate = useNavigate(); // 添加这一行
  const { t, i18n } = useTranslation();
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [width, setWidth] = useState("");
  const [height, setHeight] = useState("");
  const [percentage, setPercentage] = useState("100");
  const [langeuage, setLangeuage] = useState("en");
  const [resizeMode, setResizeMode] = useState("scale");
  const [isLoading, setIsLoading] = useState(false);
  const [receivedZipFile, setReceivedZipFile] = useState(null);

  useEffect(() => {
    document.title = t("webTitle");

    const metaDescription = document.querySelector('meta[name="description"]');
    if (metaDescription) {
      metaDescription.setAttribute("content", t("metaDescription"));
    }
  }, [i18n.language, t]);

  const handleFileChange = (event) => {
    const files = [...event.target.files];
    handleFiles(files);
  };

  const handleAddFileChange = (event) => {
    if (event.target.files && event.target.files.length > 0) {
      const newFilesArray = Array.from(event.target.files); // 将 FileList 对象转换为数组

      // 过滤掉已经存在于 selectedFiles 数组中的文件
      const uniqueNewFiles = newFilesArray.filter(
        (newFile) =>
          !selectedFiles.some(
            (selectedFile) => selectedFile.name === newFile.name
          )
      );
      handleFiles(uniqueNewFiles);
    }
  };

  const handleFiles = (files) => {
    // 如果文件数量超过最大限制，显示警告并返回
    // if (files.length > maxFiles) {
    //   alert(`一次最多只能上传 ${maxFiles} 张图片`);
    //   return;
    // }

    const previews = [];
    const updatedFiles = [];

    files.forEach((file) => {
      // 检查文件对象是否有效，如果无效则跳过
      if (!file || !file.type || !file.type.startsWith("image/")) {
        return;
      }
      console.log("file: " + file);
      const previewUrl = URL.createObjectURL(file);

      const image = new Image();
      image.src = previewUrl;
      const resized = false;
      image.onload = () => {
        const updatedFile = {
          id: file.id,
          file: file,
          type: file.type,
          size: (file.size / 1024).toFixed(2),
          name: file.name,
          width: image.width,
          height: image.height,
          state: resized,
        };

        updatedFiles.push(updatedFile);
        console.log(
          "updatedFile: " +
            updatedFile +
            " updatedFiles: " +
            updatedFiles.length
        );
        if (updatedFiles.length === files.length) {
          const combinedFiles = [...selectedFiles, ...updatedFiles];
          setSelectedFiles(combinedFiles);
          const combinedPreviewFiles = [...imagePreviews, ...previews];
          setImagePreviews(combinedPreviewFiles);
        }
      };

      previews.push(previewUrl);
    });
  };

  const resizeImage = (file, maxWidth, maxHeight, percentage) => {
    return new Promise((resolve) => {
      const image = new Image();
      image.src = URL.createObjectURL(file);
      image.onload = () => {
        const canvas = document.createElement("canvas");

        if (percentage) {
          canvas.width = Math.round(image.width * (percentage / 100));
          canvas.height = Math.round(image.height * (percentage / 100));
        } else {
          canvas.width = maxWidth;
          canvas.height = maxHeight;
        }

        const ctx = canvas.getContext("2d");

        // 计算裁剪尺寸和位置
        const aspectRatio = image.width / image.height;
        let sourceWidth = image.width;
        let sourceHeight = image.height;
        let sourceX = 0;
        let sourceY = 0;

        if (canvas.width / canvas.height > aspectRatio) {
          sourceHeight = image.width / (canvas.width / canvas.height);
          sourceY = (image.height - sourceHeight) / 2;
        } else {
          sourceWidth = image.height * (canvas.width / canvas.height);
          sourceX = (image.width - sourceWidth) / 2;
        }

        ctx.drawImage(
          image,
          sourceX,
          sourceY,
          sourceWidth,
          sourceHeight,
          0,
          0,
          canvas.width,
          canvas.height
        );

        canvas.toBlob((blob) => {
          resolve(
            new File([blob], file.name, {
              type: file.type,
              lastModified: Date.now(),
            })
          );
        }, file.type);
      };
    });
  };

  const [imagePreviews, setImagePreviews] = useState([]);

  const handleUpload = async () => {
    if (selectedFiles.length === 0) {
      alert(t("unSelectAlert"));
      return;
    }
    setIsLoading(true); // 开始显示加载指示器

    const updatedFiles = [];
    const previews = [];

    const batchSize = 50;
    const numberOfBatches = Math.ceil(selectedFiles.length / batchSize);

    for (let batch = 0; batch < numberOfBatches; batch++) {
      const start = batch * batchSize;
      const end = Math.min(start + batchSize, selectedFiles.length);
      const batchFiles = selectedFiles.slice(start, end);

      for (const fileObj of batchFiles) {
        const file = fileObj.file;
        if (!file || !file.type || !file.type.startsWith("image/")) {
          continue;
        }

        const maxWidth = width;
        const maxHeight = height;
        const resizedFile = await resizeImage(
          file,
          maxWidth,
          maxHeight,
          percentage
        );

        const previewUrl = URL.createObjectURL(resizedFile);
        const image = new Image();
        image.src = previewUrl;
        image.onload = () => {
          const updatedFile = {
            id: resizedFile.id,
            file: resizedFile,
            type: resizedFile.type,
            size: (resizedFile.size / 1024).toFixed(2),
            name: resizedFile.name,
            width: image.width,
            height: image.height,
            originalWidth: image.width, // 添加原始宽度
            originalHeight: image.height, // 添加原始高度
            originalSize: (resizedFile.size / 1024).toFixed(2),
            state: true,
          };

          updatedFiles.push(updatedFile);
          previews.push(previewUrl);
        };
      }
    }

    setSelectedFiles(updatedFiles);
    setImagePreviews(previews);
    setReceivedZipFile(updatedFiles); // 更新 receivedZipFile 字段
    setIsLoading(false); // 结束显示加载指示器
  };

  const handleDrop = (event) => {
    event.preventDefault();
    event.stopPropagation();
    handleFiles([...event.dataTransfer.files]);
  };

  const handleDragOver = (event) => {
    event.preventDefault();
    event.stopPropagation();
  };

  const percentageOptions = [];
  for (let i = 100; i >= 10; i -= 10) {
    percentageOptions.push({ value: i, label: `${i}%` });
  }

  useEffect(() => {
    const validLanguages = [
      "en",
      "zh",
      "es",
      "bn",
      "hi",
      "ar",
      "pt",
      "ru",
      "ja",
      "de",
      "fr",
      "ko",
    ]; // 在此处列出您支持的所有语言代码

    const userLanguage = navigator.language.split("-")[0]; // 获取用户的浏览器语言

    // 如果 URL 中的语言参数有效，则使用该参数设置语言
    if (lang && validLanguages.includes(lang)) {
      i18n.changeLanguage(lang);
      setLangeuage(lang);
    }
    // 否则，如果用户的浏览器语言有效，则使用浏览器语言设置语言
    else if (userLanguage && validLanguages.includes(userLanguage)) {
      i18n.changeLanguage(userLanguage);
      setLangeuage(userLanguage);
      navigate(`/${userLanguage}`);
    }
    // 否则，如果 URL 中的语言参数无效，则重定向到默认语言
    else if (lang && !validLanguages.includes(lang)) {
      navigate("/en"); // 将此替换为您的默认语言代码
    }
  }, [lang, i18n, navigate]);

  const handleLanguageChange = (value) => {
    setLangeuage(value);
    i18n.changeLanguage(value);
    navigate(`/${value}`); // 添加这一行
  };

  const languageOptions = [
    { value: "en", label: "English" },
    { value: "zh", label: "简体中文" },
    { value: "es", label: "Español" },
    { value: "bn", label: "বাংলা" },
    { value: "hi", label: "हिन्दी" },
    { value: "ar", label: "العربية" },
    { value: "pt", label: "Português" },
    { value: "ru", label: "Русский" },
    { value: "ja", label: "日本語" },
    { value: "de", label: "Deutsch" },
    { value: "fr", label: "Français" },
    { value: "ko", label: "한국어" },
  ];

  const boxStyle = {
    borderRadius: "5px",
    paddingTop: "20px",
    paddingRight: "20px",
  };

  const containerStyle = {
    display: "flex", // 添加此行以启用Flexbox布局
    justifyContent: "space-between", // 添加此行以使元素左右对齐
    width: "100%",
  };

  const textStyle = {
    fontSize: "30px",
    fontWeight: "bold",
  };

  const buttonStyle = {
    background: "#3679e7",
    width: "200px",
    height: "50px",
    color: "#FFFFFF",
    borderRadius: "8px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    margin: "0 auto",
    cursor: "pointer",
  };

  const handlePercentageChange = (value) => {
    setPercentage(value);
    setWidth("");
    setHeight("");
  };

  const formatOptionLabel = (option, meta) => {
    return <div>{option.label}</div>;
  };

  const handleDownloadAllImgsClick = async () => {
    if (receivedZipFile) {
      const zip = new JSZip();

      const imagePromises = selectedFiles.map(async (fileObj, index) => {
        const file = fileObj.file;
        const arrayBuffer = await file.arrayBuffer();
        zip.file(`${fileObj.name}`, arrayBuffer, { binary: true });
      });

      await Promise.all(imagePromises);

      const content = await zip.generateAsync({ type: "blob" });
      saveAs(content, "resized-images-" + Date.now() + ".zip");
    } else {
      alert("你还未调整图片呢");
    }
  };

  const handleReSelecteClick = () => {
    setSelectedFiles([]);
    setImagePreviews([]);
  };

  const [flip, setFlip] = useState(false);

  useEffect(() => {
    const timer = setInterval(() => {
      setFlip((prevFlip) => !prevFlip);
    }, 1000);
    return () => clearInterval(timer);
  }, []);

  const [isHovered, setIsHovered] = useState(false);

  const faqs = t("faqs", { returnObjects: true });

  return (
    <div
      className="App"
      style={{
        backgroundColor: "#f5f6fa",
        height: "100%",
        paddingBottom: "200px",
      }}
    >
      <div
        className="top-navbar"
        style={{
          backgroundColor: "#ffffff",
          width: "100%",
          height: "70px",
          display: "flex",
          justifyContent: "space-between", // 添加此行以在项目之间添加空间
          alignItems: "center",
          position: "fixed",
          boxShadow: "0px 0px 6px rgba(0, 0, 0, 0.1)",
          padding: "0 30px", // 添加此行以添加左右内边距
        }}
      >
        {/* logo */}
        <img
          src={logoImg}
          style={{
            width: "50px",
            height: "50px",
            left: "30px",
            position: "absolute",
          }}
          alt="t{logoImgAlt}"
        ></img>

        <h1
          style={{
            marginLeft: "70px",
          }}
        >
          {t("webTitle")}
        </h1>

        {/* 语言切换 */}
        <Select
          options={languageOptions}
          value={languageOptions.find((option) => option.value === langeuage)}
          onChange={(selectedOption) => {
            handleLanguageChange(selectedOption.value);
          }}
          placeholder=""
          isClearable={false}
          isSearchable={false}
          defaultValue={languageOptions[0]}
          menuPlacement="auto"
          formatOptionLabel={formatOptionLabel} // 添加此行以自定义选项显示
          styles={{
            container: (provided) => ({
              ...provided,
              width: "150px",
              position: "absolute",
              right: "60px",
              marginRight: "30px",
            }),
          }}
        />
      </div>
      <div
        className="content-wrapper"
        style={{
          width: "70vw",
          marginLeft: "15vw",
          marginRight: "15vw",
        }}
      >
        <div style={{ ...containerStyle }}>
          <p
            style={{
              ...textStyle,
              textAlign: "left",
              color: "#3679e7",
              marginTop: "90px",
            }}
          >
            {t("upload")}
          </p>
        </div>
        <div
          className="upload_container"
          style={{
            borderRadius: "5px",
            height: "500px",
            position: "relative",
            backgroundColor: "#ffffff",
            borderRadius: "8px",
          }}
          onDrop={handleDrop}
          onDragOver={handleDragOver}
        >
          {selectedFiles.length > 0 ? (
            <div
              style={{
                width: "100%",
              }}
            >
              <p
                style={{
                  position: "absolute",
                  left: "20px",
                  top: "-3px",
                  fontSize: "20px",
                }}
              >
                {t("previewTitle")}
              </p>
              <div
                style={{
                  width: "100%", // 控制线条的长度
                  height: "1px", // 控制线条的粗细
                  backgroundColor: "#f5f5f5", // 控制线条的颜色
                  position: "absolute",
                  top: "60px",
                  left: "0px",
                }}
              />

              <button
                style={{
                  width: "130px",
                  height: "34px",
                  border: "1px solid #2d5cf6",
                  borderRadius: "4px",
                  backgroundColor: "#ffffff",
                  color: "#2d5cf6",
                  position: "absolute",
                  right: receivedZipFile ? "340px" : "180px",
                  top: "10px",
                  display: "flex",
                  fontSize: "1rem",
                  justifyContent: "center",
                  alignItems: "center", // 添加此行以使文本垂直居中
                }}
                onClick={handleReSelecteClick}
              >
                {t("reUpload")}
              </button>

              <label
                style={{
                  width: "130px",
                  height: "34px",
                  border: "1px solid #2d5cf6",
                  borderRadius: "4px",
                  backgroundColor: "#ffffff",
                  color: "#2d5cf6",
                  position: "absolute",
                  right: receivedZipFile ? "180px" : "20px",
                  top: "10px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center", // 添加此行以使文本垂直居中
                }}
                htmlFor="add_file"
              >
                {t("addPictures")}
              </label>
              <input
                id="add_file"
                type="file"
                accept="image/*"
                multiple
                onChange={handleAddFileChange}
                style={{ display: "none" }}
              />
              {receivedZipFile ? (
                <div>
                  <button
                    style={{
                      width: "130px",
                      height: "34px",
                      border: "1px solid #2d5cf6",
                      borderRadius: "4px",
                      backgroundColor: "#ffffff",
                      color: "#2d5cf6",
                      position: "absolute",
                      right: "20px",
                      top: "10px",
                    }}
                    onClick={handleDownloadAllImgsClick}
                  >
                    {t("downloadAll")}
                  </button>
                </div>
              ) : null}
            </div>
          ) : null}

          <div
            style={{
              width: "100%",
              height: "100%",
              borderRadius: "5px",
            }}
          >
            {imagePreviews.length === 0 ? (
              // 当imagePreviews为空时，显示un_select_img
              <div
                id="un_select_img"
                style={{
                  height: "100%",
                  display: "flex", // 添加这一行
                  flexDirection: "column", // 添加这一行
                  justifyContent: "center", // 添加这一行
                  alignItems: "center", // 添加这一行
                  borderRadius: "5px",
                  backgroundColor: isHovered ? "#f3f5fe" : "#ffffff",
                  border: "2px dashed #bbd8fa",
                }}
                onClick={() => {
                  document.getElementById("file-upload").click();
                }}
                onMouseEnter={() => setIsHovered(true)} // 添加这一行
                onMouseLeave={() => setIsHovered(false)} // 添加这一行
              >
                <img
                  src={addLocalImg}
                  alt={t("failedToLoad")}
                  style={{
                    width: "100px",
                    height: "100px",
                  }}
                />
                <p
                  style={{
                    marginTop: "20px",
                    fontSize: "25px",
                    width: "90%",
                    textAlign: "center",
                  }}
                >
                  {t("dragAndDrop")}
                </p>
                <p
                  style={{
                    color: "#afafaf",
                    fontSize: "20px",
                    marginTop: "5px",
                    width: "90%",
                    textAlign: "center",
                  }}
                >
                  {t("imageSizeSupport")}
                </p>
                <input
                  id="file-upload"
                  type="file"
                  accept="image/*"
                  multiple
                  onChange={handleFileChange}
                  style={{ display: "none" }}
                />
              </div>
            ) : (
              // 当imagePreviews不为空时，显示preview_select_img_list
              <div
                id="preview_select_img_list"
                style={{
                  display: "grid",
                  gridTemplateColumns: "repeat(auto-fill, 345px)",
                  gridGap: "25px",
                  height: "88%",
                  position: "absolute",
                  top: "13%",
                  width: "100%",
                  overflowY: "auto",
                  paddingLeft: "23px",
                  boxSizing: "border-box", // 添加此行
                }}
              >
                {imagePreviews.map((preview, index) => (
                  <div
                    style={{
                      flexDirection: "column",
                      borderRadius: "8px",
                      width: "330px",
                      height: "160px",
                      paddingRight: "10px",
                      marginTop: "10px",
                      boxShadow: "0px 0px 6px rgba(0, 0, 0, 0.1)", // 添加此行
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        width: "auto",
                        paddingTop: "10px",
                        paddingLeft: "10px",
                        paddingRight: "10px",
                      }}
                    >
                      <label
                        style={{
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                        }}
                      >
                        {selectedFiles[index].name}
                      </label>
                    </div>
                    <div style={{ display: "flex" }}>
                      <img
                        key={index}
                        src={preview}
                        alt={t("failedToLoad")}
                        style={{
                          width: "100px",
                          height: "100px",
                          objectFit: "contain",
                          margin: "10px",
                          backgroundColor: "#edf4f5",
                        }}
                      />
                      <div
                        style={{
                          flexDirection: "column",
                          marginTop: "20px",
                        }}
                      >
                        {selectedFiles[index].state === true && (
                          <>
                            {/* 原大小 */}
                            <div style={{ display: "flex" }}>
                              <label
                                style={{
                                  paddingRight: "10px",
                                  color: "#000000",
                                }}
                              >
                                {t("size")}:
                              </label>
                              <label style={{ color: "#000000" }}>
                                {selectedFiles[index].originalSize}kb
                              </label>
                            </div>

                            {/* 原尺寸 */}
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                color: "#000000",
                              }}
                            >
                              <label style={{ paddingRight: "10px" }}>
                                {t("dimensions")}:
                              </label>
                              <label style={{ color: "#000000" }}>
                                {selectedFiles[index].originalWidth} ×{" "}
                                {selectedFiles[index].originalHeight}
                              </label>
                            </div>
                            {/* 调整后尺寸 */}
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                color: "#000000",
                              }}
                            >
                              <label style={{ paddingRight: "10px" }}>
                                {t("afterAdjustment")}:
                              </label>
                              <label style={{ color: "#ed6b64" }}>
                                {selectedFiles[index].width} ×{" "}
                                {selectedFiles[index].height}
                              </label>
                            </div>
                            {/* 调整后大小 */}
                            <div style={{ display: "flex" }}>
                              <label
                                style={{
                                  paddingRight: "10px",
                                  color: "#000000",
                                }}
                              >
                                {t("afterAdjustment")}:
                              </label>
                              <label style={{ color: "#ed6b64" }}>
                                {selectedFiles[index].size}kb
                              </label>
                            </div>
                          </>
                        )}

                        {selectedFiles[index].state === false && (
                          <>
                            {/* 文件大小 */}
                            <div style={{ display: "flex" }}>
                              <label
                                style={{
                                  paddingRight: "10px",
                                  color: "#aaaeb6",
                                }}
                              >
                                {t("size")}:
                              </label>
                              <label style={{ color: "#aaaeb6" }}>
                                {selectedFiles[index].size}kb
                              </label>
                            </div>

                            {/* 文件尺寸 */}
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                color: "#aaaeb6",
                              }}
                            >
                              <label style={{ paddingRight: "10px" }}>
                                {t("dimensions")}:
                              </label>
                              <label style={{ color: "#aaaeb6" }}>
                                {selectedFiles[index].width} ×{" "}
                                {selectedFiles[index].height}
                              </label>
                            </div>
                            {/* 当前状态 */}
                            <div style={{ display: "flex" }}>
                              <label
                                style={{
                                  paddingRight: "10px",
                                  color: "#aaaeb6",
                                }}
                              >
                                {t("state")}:
                              </label>
                              <label style={{ color: "#aaaeb6" }}>
                                {t("unResize")}
                              </label>
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
        <p style={{ ...textStyle, textAlign: "left", color: "#3679e7" }}>
          {t("CustomizeSizeTitle")}
        </p>
        <div className="content-container">
          <div className="resize-options">
            <div className="resize-option">
              <Radio
                value="scale"
                checked={resizeMode === "scale"}
                onClick={() => setResizeMode("scale")}
              >
                {t("proportionalScaling")}:
              </Radio>
              <Creatable
                options={percentageOptions}
                value={percentageOptions.find(
                  (option) => option.value === percentage
                )}
                onChange={(selectedOption) => {
                  handlePercentageChange(selectedOption.value);
                }}
                placeholder=""
                isClearable={false}
                defaultValue={percentageOptions[0]}
                menuPlacement="auto"
                formatCreateLabel={(inputValue) => `${inputValue}%`}
                formatOptionLabel={formatOptionLabel}
                className="percentage-input"
                isDisabled={resizeMode !== "scale"}
              />
            </div>
            <div className="resize-option">
              <Radio
                value="resize"
                checked={resizeMode === "resize"}
                onClick={() => setResizeMode("resize")}
              >
                {t("specifyDimensions")}:
              </Radio>
              <div className="dimensions-inputs">
                <Input
                  id="width_input"
                  className="dimension-input"
                  value={width}
                  type="number"
                  min="1"
                  addonBefore={t("width")}
                  disabled={resizeMode !== "resize"}
                  onChange={(e) => {
                    let inputValue = e.target.value;
                    if (inputValue <= 0) {
                      inputValue = 1;
                    }
                    setWidth(inputValue);
                    setPercentage("");
                  }}
                />
                <label htmlFor="height_input" className="multiply-symbol">
                  ×
                </label>
                <Input
                  id="height_input"
                  className="dimension-input"
                  value={height}
                  type="number"
                  min="1"
                  addonBefore={t("height")}
                  disabled={resizeMode !== "resize"}
                  onChange={(e) => {
                    let inputValue = e.target.value;
                    if (inputValue <= 0) {
                      inputValue = 1;
                    }
                    setHeight(inputValue);
                    setPercentage("");
                  }}
                />
                <label htmlFor="height_input" className="units-label">
                  px
                </label>
              </div>
            </div>
          </div>
          <div onClick={handleUpload} className="start_upload">
            {t("StartResizeTitle")}
          </div>
        </div>

        <h2 style={{ ...textStyle, textAlign: "left", color: "#3679e7" }}>
          FAQ
        </h2>
        {Array.isArray(faqs) &&
          faqs.map((faq, index) => (
            <FAQ key={index} question={faq.question} answers={faq.answers} />
          ))}
        {/* 空白位置 */}
        <div style={{ height: "100px" }}></div>
      </div>
      {isLoading ? (
        <div className="loading-overlay">
          <div className="loading-indicator">
            <div className="spinner" />
            <div className="loading-text"> {t("loadingText")}</div>
          </div>
        </div>
      ) : null}
    </div>
  );
}

export default MainContainer;
