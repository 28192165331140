// i18n.js
import i18n from "i18next";
import { initReactI18next } from "react-i18next";

const resources = {
  // 英语
  en: {
    translation: {
      upload: "①Upload",
      resizeImage: "Batch Resize Images",
      selectLocalImage: "Select Local Image",
      dragAndDrop: "Drag and drop image files here or click here",
      imageSizeSupport:
        "Resize single or multiple images at once using our free online bulk image resizer, supporting various formats such as jpg, png, jpeg, svg, webp, and gif. Easily adjust image size for batch picture resizing.",
      history: "History",
      CustomizeSizeTitle: "②Customize Size",
      StartResizeTitle: "Start Resizing",
      webTitle: "Online Bulk Image Resizer",
      metaDescription:
        "Free online bulk image resizer and batch picture resizer. Resize over 1000 images at once according to ratio or pixels, supporting various formats such as jpg, png, jpeg, etc. No need to download software, adjust image size online for free.",
      previewTitle: "File List",
      reUpload: "Re-upload",
      addPictures: "Add Pictures",
      downloadAll: "Download All (zip)",
      failedToLoad: "Failed to Load",
      size: "Size",
      dimensions: "Dims",
      afterAdjustment: "Post-adjust",
      state: "State",
      unResize: "To be processed",
      proportionalScaling: "Proportional Scaling",
      specifyDimensions: "Specify Dimensions",
      width: "width",
      height: "height",
      unSelectAlert: "You haven't selected an image yet.",
      loadingText:
        "Hey! Adjusting images locally, no backend upload~ Ensuring privacy, safe and fast!",
      logoImgAlt: "bulk image resizer logo",
      faqs: [
        {
          question:
            "Q: How to use this free online tool to resize multiple images at once?",
          answers: [
            "Move your mouse over the dashed box at the top, and click anywhere inside it to open a file selection dialog. You can either batch-select the photos or images you want to modify or directly drag and drop images into the dashed box. This way, you can easily use the image resize for free feature.",
            "Based on your needs, you can choose to scale proportionally or specify pixel values directly. If you choose to scale proportionally, enter the percentage directly; if you choose to specify pixel values, fill in the width and height in the corresponding input boxes. This makes the image resize free online process simple and user-friendly.",
            <>
              Click the <strong>Start Resizing</strong> button on the far right
              to generate images that meet your expectations immediately. This
              allows you to quickly adjust the size of multiple images.
            </>,
            <>
              Finally, click the <strong>Download All</strong> button in the top
              right corner to save the resized images to your device.
            </>,
          ],
        },
        {
          question: "Q: What image formats does your tool support?",
          answers: [
            "Our tool supports a wide range of popular image formats, including JPG, JPEG, PNG, SVB, GIF, and WebP. ",
            "You can easily resize many images at once using our tool, regardless of their file types. Make sure to check the file format before uploading your images to ensure compatibility with our bulk image resizing tool.",
          ],
        },
      ],
      // ...add other texts that need translation
    },
  },
  // 西班牙语
  es: {
    translation: {
      upload: "①Subir",
      resizeImage: "Redimensionar imágenes por lotes",
      selectLocalImage: "Seleccionar imagen local",
      dragAndDrop:
        "Arrastre y suelte archivos de imagen aquí o haga clic en el botón de abajo 『Seleccionar imagen local』",
      imageSizeSupport:
        "Redimensiona imágenes individuales o múltiples a la vez con nuestro redimensionador de imágenes en línea gratuito, compatible con varios formatos como jpg, png, jpeg, svg, webp y gif. Ajusta fácilmente el tamaño de las imágenes para cambiar el tamaño de las imágenes por lotes.",
      history: "Historial",
      CustomizeSizeTitle: "②Personalizar tamaño",
      StartResizeTitle: "Comenzar a ajustar",
      webTitle: "Redimensionador de imágenes",
      metaDescription:
        "Ajuste por lotes del tamaño de la imagen, compatible con varios formatos como jpg, png, jpeg, etc. Redimensione más de 1000 imágenes a la vez según la proporción o los píxeles, no es necesario descargar software, ajuste el tamaño de la imagen en línea de forma gratuita",
      previewTitle: "Tipo de archivo",
      reUpload: "Subir de nuevo",
      addPictures: "Agregar imágenes",
      downloadAll: "Descargar todo (zip)",
      failedToLoad: "Error al cargar",
      size: "Tamaño",
      dimensions: "Dim",
      afterAdjustment: "Post-ajuste",
      state: "Estado",
      proportionalScaling: "Escalado proporcional",
      specifyDimensions: "Especificar dimensiones",
      width: "ancho",
      height: "altura",
      unSelectAlert: "Todavía no has seleccionado una imagen.",
      loadingText:
        "¡Hola! Ajustando imágenes localmente, sin subir al backend~ ¡Asegurando privacidad, seguro y rápido!",
      unResize: "Por procesar",
      logoImgAlt: "redimensionador de imágenes masivas logo",
      faqs: [
        {
          question:
            "Q: ¿Cómo utilizar esta herramienta en línea gratuita para cambiar el tamaño de varias imágenes a la vez?",
          answers: [
            "Mueva el cursor sobre el cuadro punteado en la parte superior y haga clic en cualquier lugar dentro de él para abrir un cuadro de diálogo de selección de archivos. Puede seleccionar por lotes las fotos o imágenes que desea modificar o arrastrar y soltar imágenes directamente en el cuadro punteado. De esta manera, puede utilizar fácilmente la función de cambio de tamaño de imagen gratis.",
            "Según sus necesidades, puede elegir escalar proporcionalmente o especificar valores de píxeles directamente. Si elige escalar proporcionalmente, ingrese el porcentaje directamente; si elige especificar valores de píxeles, complete el ancho y la altura en los cuadros de entrada correspondientes. Esto hace que el proceso de cambio de tamaño de imagen en línea gratuito sea simple y fácil de usar.",
            <>
              Haga clic en el botón{" "}
              <strong>Comenzar a cambiar el tamaño</strong> en el extremo
              derecho para generar imágenes que cumplan con sus expectativas de
              inmediato. Esto le permite ajustar rápidamente el tamaño de varias
              imágenes.
            </>,
            <>
              Finalmente, haga clic en el botón <strong>Descargar todo</strong>{" "}
              en la esquina superior derecha para guardar las imágenes
              redimensionadas en su dispositivo.
            </>,
          ],
        },
        {
          question: "Q: What image formats does your tool support?",
          answers: [
            "Our tool supports a wide range of popular image formats, including JPG, JPEG, PNG, SVB, GIF, and WebP. ",
            "You can easily resize many images at once using our tool, regardless of their file types. Make sure to check the file format before uploading your images to ensure compatibility with our bulk image resizing tool.",
          ],
        },
      ],
      // ...agregar otros textos que necesiten traducción
    },
  },
  zh: {
    translation: {
      upload: "①上传",
      resizeImage: "批量调整图像大小",
      selectLocalImage: "选择本地图片",
      dragAndDrop: "将图片文件拖拽至此处 或 点击下方『选择本地图片』按钮",
      imageSizeSupport:
        "使用我们免费的在线批量图片调整器，一次调整单张或多张图片的尺寸，支持各种格式，如 jpg、png、jpeg、svg、webp 和 gif，轻松调整批量图片的尺寸。",
      history: "历史记录",
      CustomizeSizeTitle: "②自定义设置尺寸",
      StartResizeTitle: "开始调整",
      webTitle: "批量图片调整器",
      metaDescription:
        "批量调整图片尺寸, 支持jpg、png、jpeg、等多种格式的尺寸.支持根据比例或者像素, 一次性裁剪1000+张图片, 无需下载软件, 在线免费调整图片大小",
      previewTitle: "文件列表",
      reUpload: "重新上传",
      addPictures: "添加图片",
      downloadAll: "下载全部(zip)",
      failedToLoad: "加载失败",
      size: "大小",
      dimensions: "尺寸",
      afterAdjustment: "调整后",
      state: "状态",
      proportionalScaling: "等比例缩放",
      specifyDimensions: "指定尺寸",
      width: "宽",
      height: "高",
      unSelectAlert: "你还未选择图片呢",
      loadingText: "嘿！正在本地调整图片，没上传后台哦~ 保障隐私，安全又快捷！",
      unResize: "待处理",
      logoImgAlt: "批量图像调整器 logo",
      faqs: [
        {
          question: "Q: 如何使用这个免费在线工具一次调整多张图片的尺寸？",
          answers: [
            "将鼠标移动到顶部的虚线框内，点击任意位置以打开文件选择对话框。您可以批量选择要修改的照片或图像，也可以直接将图像拖放到虚线框中。这样，您可以轻松地使用免费调整图像尺寸的功能。",
            "根据您的需求，您可以选择按比例缩放或直接指定像素值。如果选择按比例缩放，请直接输入百分比；如果选择指定像素值，请在相应的输入框中填写宽度和高度。这使得在线免费调整图像尺寸的过程变得简单且用户友好。",
            <>
              点击最右侧的<strong>开始调整</strong>
              按钮，立即生成符合您预期的图像。这样，您可以快速调整多张图像的尺寸。
            </>,
            <>
              最后，点击右上角的<strong>下载全部</strong>
              按钮，将调整后的图像保存到您的设备上。
            </>,
          ],
        },
        {
          question: "Q: 你们的工具支持哪些图像格式？",
          answers: [
            "我们的工具支持多种流行的图像格式，包括JPG、JPEG、PNG、SVB、GIF和WebP。",
            "您可以使用我们的工具轻松地一次调整多个图像的大小，无论它们的文件类型如何。在上传图像之前，请确保检查文件格式，以确保与我们的批量图像调整工具兼容。",
          ],
        },
      ],
    },
  },

  // 孟加拉语
  bn: {
    translation: {
      upload: "①আপলোড",
      resizeImage: "ব্যাচ রিসাইজ চিত্র",
      selectLocalImage: "স্থানীয় চিত্র নির্বাচন করুন",
      dragAndDrop:
        "এখানে চিত্র ফাইলগুলি টেনে আনুন বা নীচের বোতামটি ক্লিক করুন 『স্থানীয় চিত্র নির্বাচন করুন』",
      imageSizeSupport:
        "আমাদের বিনামূল্যে অনলাইন বাল্ক ইমেজ রিসাইজার ব্যবহার করে একবারে একটি বা একাধিক চিত্রের আকার পরিবর্তন করুন, যেমন jpg, png, jpeg, svg, webp এবং gif এর মতো বিভিন্ন ফরম্যাট সমর্থন করে। ব্যাচ চিত্র পুনরায় আকার করার জন্য সহজে চিত্রের আকার সমন্বয় করুন।",
      history: "ইতিহাস",
      CustomizeSizeTitle: "②আকার কাস্টমাইজ করুন",
      StartResizeTitle: "রিসাইজ শুরু করুন",
      webTitle: "বাল্ক ইমেজ রিসাইজার",
      metaDescription:
        "ব্যাচে চিত্রের আকার পরিবর্তন, jpg, png, jpeg ইত্যাদি বিভিন্ন ফর্ম্যাট সমর্থন করে। অনুপাত বা পিক্সেলের অনুযায়ী 1000+ চিত্রের আকার একবারে পরিবর্তন করুন, সফ্টওয়্যার ডাউনলোড করার দরকার নেই, অনলাইনে চিত্রের আকার নিঃশুল্ক পরিবর্তন করুন",
      previewTitle: "ফাইলের ধরন",
      reUpload: "পুনরায় আপলোড করুন",
      addPictures: "চিত্র যোগ করুন",
      downloadAll: "সমস্ত ডাউনলোড (zip)",
      failedToLoad: "লোড করতে ব্যর্থ",
      size: "আকার",
      dimensions: "পরিমাপ",
      afterAdjustment: "সমন্বয়ের পরে",
      state: "অবস্থা",
      proportionalScaling: "অনুপাতিক স্কেলিং",
      specifyDimensions: "পরিমাপ নির্দিষ্ট করুন",
      width: "প্রস্থ",
      height: "উচ্চতা",
      unSelectAlert: "আপনি এখনও কোনও চিত্র নির্বাচন করেন নি।",
      loadingText:
        "হে! স্থানীয়ভাবে চিত্র সমন্বয় করা হচ্ছে, ব্যাকএন্ড আপলোড নেই~ গোপনীয়তা নিশ্চিত করা, নিরাপদ এবং দ্রুত!",
      unResize: "প্রক্রিয়া অপেক্ষা",
      logoImgAlt: "বাল্ক ইমেজ রিসাইজার logo",
      faqs: [
        {
          question:
            "Q: এই ফ্রি অনলাইন টুলটি একবারে একাধিক চিত্রের আকার পরিবর্তন করার উপায়?",
          answers: [
            "শীর্ষের ড্যাশড বক্সের উপরে আপনার মাউসটি সরান এবং এটি খোলার জন্য ফাইল নির্বাচন ডায়ালগটি খুলতে এর ভেতরে যে কোনও জায়গায় ক্লিক করুন। আপনি পরিবর্তনের জন্য আপনি যে ছবি বা চিত্রগুলি চান তা ব্যাচ-নির্বাচন করতে পারেন বা সরাসরি ড্যাশড বক্সে চিত্র টেনে আনতে পারেন। এইভাবে, আপনি চিত্রের আকার বিনামূল্যে পরিবর্তনের বৈশিষ্ট্যটি সহজে ব্যবহার করতে পারেন।",
            "আপনার প্রয়োজনীয়তার উপর ভিত্তি করে, আপনি পরিমাণমূলকভাবে স্কেল করতে বা পিক্সেলের মান সরাসরি নির্দিষ্ট করতে চয়ন করতে পারেন। যদি আপনি পরিমাণমূলকভাবে স্কেল করতে চান, সরাসরি শতাংশ লিখুন; যদি আপনি পিক্সেলের মান নির্দিষ্ট করতে চান, সম্পর্কিত ইনপুট বাক্সগুলিতে প্রস্থ এবং উচ্চতা পূরণ করুন। এটি চিত্রের আকার বিনামূল্যে অনলাইন পরিবর্তনের প্রক্রিয়াটি সহজ এবং ব্যবহারকারী বন্ধুত্বপূর্ণ করে তোলে",
            <>
              দক্ষিণ দিকের চরমে <strong>পরিমাপ পরিবর্তন শুরু</strong> বোতামে
              ক্লিক করুন যাতে করে আপনার প্রত্যাশার সাথে মিলে যাওয়া চিত্রগুলি
              তৈরি হয়। এর মাধ্যমে আপনি একাধিক চিত্রের আকার দ্রুত সমন্বয় করতে
              পারেন।
            </>,
            <>
              পরিশেষে, উপরের ডান কোণে <strong>সব ডাউনলোড করুন</strong> বোতামে
              ক্লিক করুন যাতে আপনার ডিভাইসে পরিবর্তিত চিত্রগুলি সংরক্ষণ করা হয়।
            </>,
          ],
        },
        {
          question: "Q: আপনার সরঞ্জামটি কোন চিত্র বিন্যাসগুলি সমর্থন করে?",
          answers: [
            "আমাদের সরঞ্জামটি জেপিজি, জেপিইজি, পিএনজি, এসভিবি, জিআইএফ এবং ওয়েবপি সহ জনপ্রিয় চিত্র বিন্যাসের বিস্তৃত পরিসর সমর্থন করে।",
            "আপনি আমাদের সরঞ্জামটি ব্যবহার করে ফাইলের প্রকারের বিবেচনা না করে একবারে অনেক চিত্রের আকার সহজেই পরিবর্তন করতে পারেন। আমাদের বাল্ক চিত্র আকার পরিবর্তন সরঞ্জামের সাথে সামঞ্জস্য নিশ্চিত করতে চিত্র আপলোড করার আগে নিশ্চিত করুন যে ফাইলের ফরম্যাটটি চেক করা হয়েছে।",
          ],
        },
        // 更多孟加拉语问题和答案
      ],
      // ...add other texts that need translation
    },
  },
  // 印地语：
  hi: {
    translation: {
      upload: "①अपलोड",
      resizeImage: "बैच रीसाइज़ इमेजेस",
      selectLocalImage: "स्थानीय छवि का चयन करें",
      dragAndDrop:
        "यहां छवि फ़ाइलें खींचें और छोड़ें या नीचे दिए गए बटन पर क्लिक करें 『स्थानीय छवि का चयन करें』",
      imageSizeSupport:
        "हमारे नि:शुल्क ऑनलाइन थोक छवि रीसाइज़र का उपयोग करके एक बार में एक या एकाधिक छवियों का आकार बदलें, जेपीजी, पीएनजी, जेपीईजी, एसवीजी, वेबपी और जीआईएफ जैसे विभिन्न प्रारूपों का समर्थन करते हैं। बैच चित्र आकार परिवर्तन के लिए आसानी से छवि का आकार समायोजित करें।",
      history: "इतिहास",
      CustomizeSizeTitle: "②आकार अनुकूलित करें",
      StartResizeTitle: "रीसाइज़िंग शुरू करें",
      webTitle: "थोक छवि रीसाइज़र",
      metaDescription:
        "बैच रीसाइज़ इमेजेस, जैसे कि jpg, png, jpeg, आदि विभिन्न प्रारूपों का समर्थन करता है। अनुपात या पिक्सेल के अनुसार एक बार में 1000+ इमेज के आकार को बदलें, सॉफ़्टवेयर डाउनलोड करने की आवश्यकता नहीं है, इमेज के आकार को ऑनलाइन मुफ़्त में समायोजित करें",
      previewTitle: "फ़ाइल प्रकार",
      reUpload: "पुनर-अपलोड",
      addPictures: "चित्र जोड़ें",
      downloadAll: "सभी डाउनलोड करें (zip)",
      failedToLoad: "लोड करने में विफल",
      size: "आकार",
      dimensions: "आया",
      afterAdjustment: "समायोजन बाद",
      state: "स्थिति",
      proportionalScaling: "अनुपातिक स्केलिंग",
      specifyDimensions: "आयाम निर्दिष्ट करें",
      width: "चौड़ाई",
      height: "ऊंचाई",
      unSelectAlert: "आपने अभी तक कोई छवि नहीं चुनी है।",
      loadingText:
        "हे! स्थानीय रूप से छवियों को समायोजित कर रहा है, कोई बैकएंड अपलोड नहीं~ गोपनीयता की सुरक्षा, सुरक्षित और तेज!",
      unResize: "प्रक्रिया के लिए बाकी",
      logoImgAlt: "बल्क इमेज रीसाइज़र logo",
      faqs: [
        {
          question:
            "Q: इस मुफ्त ऑनलाइन टूल का उपयोग करके एक साथ कई छवियों का आकार कैसे बदलें?",
          answers: [
            "माउस को ऊपर के डैश्ड बॉक्स पर ले जाएं और फ़ाइल चयन वार्तालाप खोलने के लिए उसके भीतर कहीं भी क्लिक करें। आप बैच में वह फ़ोटो या छवियाँ चुन सकते हैं जिन्हें आप बदलना चाहते हैं या सीधे डैश्ड बॉक्स में छवियों को खींचकर छोड़ सकते हैं। इस तरह से, आप बिना किसी परेशानी के नि: शुल्क छवि आकार बदलने की सुविधा का उपयोग कर सकते हैं।",
            "आपकी आवश्यकताओं के आधार पर, आप समानुपातिक रूप से स्केल करने के लिए या पिक्सेल मानों को सीधे निर्दिष्ट करने के लिए चुन सकते हैं। यदि आप समानुपातिक रूप से स्केल करने का चयन करते हैं, तो प्रतिशत को सीधे दर्ज करें; यदि आप पिक्सेल मानों को निर्दिष्ट करने का चयन करते हैं, तो संबंधित प्रविष्टि बक्सों में चौड़ाई और ऊँचाई भरें। इससे नि: शुल्क ऑनलाइन छवि आकार परिवर्तन प्रक्रिया सरल और उपयोगकर्ता के अनुकूल होती है।",
            <>
              इमीजें तुरंत उत्पन्न करने के लिए दाईं ओर{" "}
              <strong>आकार परिवर्तन शुरू करें</strong> बटन पर क्लिक करें, जो
              आपकी उम्मीदों को पूरा करती हैं। इससे आप कई छवियों के आकार को तेजी
              से समायोजित कर सकते हैं।
            </>,
            <>
              अंत में, उपरी दाएं कोने में <strong>सभी डाउनलोड करें</strong> बटन
              पर क्लिक करके आकार में बदली गई छवियों को अपनी उपकरण में सहेजें।
            </>,
          ],
        },
        {
          question:
            "Q: आपके उपकरण किस प्रकार के छवि प्रारूपों का समर्थन करते हैं?",
          answers: [
            "हमारे उपकरण जेपीजी, जेपीईजी, पीएनजी, एसवीबी, जीआईएफ और वेबपी सहित लोकप्रिय छवि प्रारूपों की एक विस्तृत श्रेणी का समर्थन करते हैं।",
            "फ़ाइल प्रकार की परवाह किए बिना, आप हमारे उपकरण का उपयोग करके एक साथ कई छवियों का आकार आसानी से बदल सकते हैं। हमारे बल्क छवि आकार परिवर्तन उपकरण के साथ संगतता सुनिश्चित करने के लिए छवियों को अपलोड करने से पहले फ़ाइल प्रारूप की जाँच करें।",
          ],
        },
        // 更多印地语问题和答案
      ],
      // ...add other texts that need translation
    },
  },
  // 阿拉伯语：
  ar: {
    translation: {
      upload: "①تحميل",
      resizeImage: "تغيير حجم الصور بالجملة",
      selectLocalImage: "حدد الصورة المحلية",
      dragAndDrop:
        "اسحب وأفلت ملفات الصور هنا أو انقر فوق الزر أدناه 『حدد الصورة المحلية』",
      imageSizeSupport:
        "قم بتغيير حجم صورة واحدة أو عدة صور في آن واحد باستخدام مُغيّر حجم الصور المجاني عبر الإنترنت الخاص بنا، مع دعم تنسيقات متنوعة مثل jpg وpng وjpeg وsvg وwebp وgif. قم بضبط حجم الصورة بسهولة لتغيير حجم الصور بالجملة.",
      history: "التاريخ",
      CustomizeSizeTitle: "②تخصيص الحجم",
      StartResizeTitle: "ابدأ في تغيير الحجم",
      webTitle: "مُغيّر حجم الصور",
      metaDescription:
        "تغيير حجم الصور بالجملة ، مع دعم مجموعة متنوعة من التنسيقات مثل jpg و png و jpeg ، إلخ. قم بتغيير حجم أكثر من 1000 صورة في وقت واحد وفقًا للنسبة أو البكسل ، لا يلزم تنزيل البرامج ، قم بتعديل حجم الصورة عبر الإنترنت مجانًا",
      previewTitle: "نوع الملف",
      reUpload: "إعادة تحميل",
      addPictures: "أضف صور",
      downloadAll: "تحميل الكل (zip)",
      failedToLoad: "فشل التحميل",
      size: "الحجم",
      dimensions: "الأبعاد",
      afterAdjustment: "بعد التعديل",
      state: "الحالة",
      proportionalScaling: "تحجيم متناسب",
      specifyDimensions: "حدد الأبعاد",
      width: "عرض",
      height: "ارتفاع",
      unSelectAlert: "لم تقم بتحديد صورة بعد.",
      loadingText:
        "مرحبًا! تعديل الصور محليًا، بدون تحميل إلى الخلفية~ ضمان الخصوصية، آمن وسريع!",
      unResize: "في انتظار المعالجة",
      logoImgAlt: "موزع تغيير حجم الصور logo",
      faqs: [
        {
          question:
            "Q: كيفية استخدام هذه الأداة المجانية عبر الإنترنت لتغيير حجم الصور المتعددة في وقت واحد؟",
          answers: [
            "قم بتحريك الماوس الخاص بك فوق المربع المتقطع في الأعلى، وانقر في أي مكان داخله لفتح مربع حوار اختيار الملف. يمكنك إما تحديد الصور أو الصور المجمعة التي ترغب في تعديلها أو سحب الصور وإفلاتها مباشرةً في المربع المتقطع. بهذه الطريقة، يمكنك استخدام ميزة تغيير حجم الصورة مجانًا بسهولة.",
            "استنادًا إلى احتياجاتك، يمكنك اختيار التحجيم بشكل متناسب أو تحديد قيم البكسل مباشرة. إذا اخترت التحجيم بشكل متناسب، أدخل النسبة المئوية مباشرةً؛ إذا اخترت تحديد قيم البكسل، املأ العرض والارتفاع في مربعات الإدخال المقابلة. يجعل ذلك عملية تغيير حجم الصورة المجانية عبر الإنترنت سهلة وودية للمستخدم.",
            <>
              انقر فوق زر <strong>بدء التغيير</strong> على الجانب الأيمن البعيد
              لإنشاء الصور التي تفي بتوقعاتك على الفور. يتيح لك ذلك ضبط حجم
              الصور المتعددة بسرعة.
            </>,
            <>
              في النهاية، انقر فوق زر <strong>تنزيل الكل</strong> في الزاوية
              العلوية اليمنى لحفظ الصور المعدلة على جهازك.
            </>,
          ],
        },
        {
          question: "Q: ما هي تنسيقات الصور التي يدعمها أداتك؟",
          answers: [
            "تدعم أداتنا مجموعة واسعة من تنسيقات الصور الشائعة ، بما في ذلك JPG و JPEG و PNG و SVB و GIF و WebP.",
            "يمكنك بسهولة تغيير حجم العديد من الصور في وقت واحد باستخدام أداتنا ، بغض النظر عن أنواع الملفات. تأكد من التحقق من تنسيق الملف قبل تحميل الصور لضمان التوافق مع أداة تغيير حجم الصور الخاصة بنا.",
          ],
        },
        // 更多印地语问题和答案
      ],
      // ...add other texts that need translation
    },
  },
  // 葡萄牙语
  pt: {
    translation: {
      upload: "①Fazer upload",
      resizeImage: "Redimensionar imagens em lote",
      selectLocalImage: "Selecionar imagem local",
      dragAndDrop:
        "Arraste e solte arquivos de imagem aqui ou clique no botão abaixo 『Selecionar imagem local』",
      imageSizeSupport:
        "Redimensione uma ou várias imagens de uma só vez usando nosso redimensionador de imagens online gratuito, com suporte a vários formatos, como jpg, png, jpeg, svg, webp e gif. Ajuste facilmente o tamanho da imagem para redimensionamento de imagens em lote.",
      history: "Histórico",
      CustomizeSizeTitle: "②Personalizar tamanho",
      StartResizeTitle: "Começar a redimensionar",
      webTitle: "Redimensionador de imagens",
      metaDescription:
        "Redimensionamento em lote de imagens, suportando vários formatos, como jpg, png, jpeg, etc. Redimensione mais de 1000 imagens de uma vez de acordo com a proporção ou pixels, não é necessário baixar software, ajuste o tamanho da imagem online gratuitamente",
      previewTitle: "Tipo de arquivo",
      reUpload: "Fazer upload novamente",
      addPictures: "Adicionar fotos",
      downloadAll: "Baixar tudo (zip)",
      failedToLoad: "Falha ao carregar",
      size: "Tamanho",
      dimensions: "Dimensões",
      afterAdjustment: "Após ajuste",
      state: "Estado",
      proportionalScaling: "Dimensionamento proporcional",
      specifyDimensions: "Especificar dimensões",
      width: "largura",
      height: "altura",
      unSelectAlert: "Você ainda não selecionou uma imagem.",
      loadingText:
        "Olá! Ajustando imagens localmente, sem upload para o backend~ Garantindo privacidade, seguro e rápido!",
      unResize: "A ser processado",
      logoImgAlt: "redimensionador de imagem em massa logo",
      faqs: [
        {
          question:
            "Q: Como usar essa ferramenta online gratuita para redimensionar várias imagens de uma vez?",
          answers: [
            "Mova o mouse sobre a caixa pontilhada na parte superior e clique em qualquer lugar dentro dela para abrir uma caixa de diálogo de seleção de arquivo. Você pode selecionar em lote as fotos ou imagens que deseja modificar ou arrastar e soltar imagens diretamente na caixa pontilhada. Dessa forma, você pode usar facilmente o recurso de redimensionamento de imagem gratuito.",
            "Com base em suas necessidades, você pode escolher escalar proporcionalmente ou especificar valores de pixel diretamente. Se você escolher escalar proporcionalmente, insira a porcentagem diretamente; se você escolher especificar valores de pixel, preencha a largura e a altura nas caixas de entrada correspondentes. Isso torna o processo de redimensionamento de imagem online gratuito simples e amigável ao usuário.",
            <>
              Clique no botão <strong>Iniciar redimensionamento</strong> no
              canto direito para gerar imagens que atendam às suas expectativas
              imediatamente. Isso permite ajustar rapidamente o tamanho de
              várias imagens.
            </>,
            <>
              Por fim, clique no botão <strong>Baixar tudo</strong> no canto
              superior direito para salvar as imagens redimensionadas em seu
              dispositivo.
            </>,
          ],
        },
        {
          question: "Q: Quais formatos de imagem a sua ferramenta suporta?",
          answers: [
            "Nossa ferramenta suporta uma ampla gama de formatos de imagem populares, incluindo JPG, JPEG, PNG, SVB, GIF e WebP.",
            "Você pode facilmente redimensionar várias imagens de uma vez usando nossa ferramenta, independentemente dos tipos de arquivo. Certifique-se de verificar o formato do arquivo antes de fazer o upload das imagens para garantir a compatibilidade com nossa ferramenta de redimensionamento de imagem em massa.",
          ],
        },
        // Mais perguntas e respostas em português
      ],
      // ...add other texts that need translation
    },
  },
  // 俄语
  ru: {
    translation: {
      upload: "①Загрузить",
      resizeImage: "Пакетное изменение размера изображений",
      selectLocalImage: "Выберите локальное изображение",
      dragAndDrop:
        "Перетащите файлы изображений сюда или щелкните кнопку ниже 『Выберите локальное изображение』",
      imageSizeSupport:
        "Изменяйте размер одного или нескольких изображений одновременно с помощью нашего бесплатного онлайн-утилиты для массового изменения размеров изображений, поддерживающей различные форматы, такие как jpg, png, jpeg, svg, webp и gif. Легко настраивайте размер изображения для изменения размера изображений пакетной обработки.",
      history: "История",
      CustomizeSizeTitle: "②Настроить размер",
      StartResizeTitle: "Начать изменение размера",
      webTitle: "Массовый изменитель размеров",
      metaDescription:
        "Пакетное изменение размера изображений, поддержка различных форматов, таких как jpg, png, jpeg и т. д. Изменение размера более 1000 изображений одновременно в соответствии с пропорциями или пикселями, не нужно загружать программное обеспечение, настройте размер изображения онлайн бесплатно",
      previewTitle: "Тип файла",
      reUpload: "Загрузить заново",
      addPictures: "Добавить изображения",
      downloadAll: "Скачать все (zip)",
      failedToLoad: "Не удалось загрузить",
      size: "Размер",
      dimensions: "Разм",
      afterAdjustment: "После корр",
      state: "Состояние",
      proportionalScaling: "Пропорциональное масштабирование",
      specifyDimensions: "Указать размеры",
      width: "ширина",
      height: "высота",
      unSelectAlert: "Вы еще не выбрали изображение.",
      loadingText:
        "Привет! Изменение размера изображений локально, без загрузки на сервер~ Обеспечение конфиденциальности, безопасно и быстро!",
      unResize: "В обработке",
      logoImgAlt: "массовое изменение размера изображений logo",
      faqs: [
        {
          question:
            "Q: Как использовать этот бесплатный онлайн-инструмент для изменения размера нескольких изображений одновременно?",
          answers: [
            "Переместите указатель мыши над пунктирным прямоугольником в верхней части и щелкните в любом месте внутри него, чтобы открыть диалоговое окно выбора файла. Вы можете выбрать пакетно фотографии или изображения, которые хотите изменить, или перетащить их прямо в пунктирный прямоугольник. Таким образом, вы можете легко использовать функцию бесплатного изменения размера изображения.",
            "В зависимости от ваших потребностей вы можете выбрать масштабирование пропорционально или указать значения пикселей напрямую. Если вы выберете пропорциональное масштабирование, введите процент напрямую; если вы выберете указание значений пикселей, заполните ширину и высоту в соответствующих полях ввода. Это делает процесс изменения размера изображения онлайн бесплатным, простым и удобным для пользователя.",
            <>
              Нажмите кнопку <strong>Начать изменение размера</strong> на правой
              стороне, чтобы сразу создать изображения, соответствующие вашим
              ожиданиям. Это позволяет быстро настроить размер нескольких
              изображений.
            </>,
            <>
              Наконец, нажмите кнопку <strong>Скачать все</strong> в правом
              верхнем углу, чтобы сохранить измененные изображения на вашем
              устройстве.
            </>,
          ],
        },
        {
          question: "Q: Какие форматы изображений поддерживает ваш инструмент?",
          answers: [
            "Наш инструмент поддерживает широкий спектр популярных форматов изображений, включая JPG, JPEG, PNG, SVB, GIF и WebP.",
            "Вы можете легко изменить размер нескольких изображений одновременно с помощью нашего инструмента, независимо от типов файлов. Перед загрузкой изображений убедитесь, что вы проверили формат файла, чтобы обеспечить совместимость с нашим инструментом массового изменения размера изображений.",
          ],
        },
        // Больше вопросов и ответов на русском языке
      ],
      // ...add other texts that need translation
    },
  },
  // 日语
  ja: {
    translation: {
      upload: "①アップロード",
      resizeImage: "バッチサイズ変更イメージ",
      selectLocalImage: "ローカル画像を選択",
      dragAndDrop:
        "画像ファイルをここにドラッグアンドドロップするか、以下のボタンをクリックして『ローカル画像を選択』",
      imageSizeSupport:
        "無料のオンライン一括画像サイズ変更ツールを使用して、jpg、png、jpeg、svg、webp、gif などのさまざまな形式をサポートする単一または複数の画像のサイズを一度に変更します。バッチ画像のサイズ変更のために簡単に画像サイズを調整します。",
      history: "履歴",
      CustomizeSizeTitle: "②サイズをカスタマイズ",
      StartResizeTitle: "サイズ変更を開始",
      webTitle: "オンラインで画像のサイズを一括変更",
      metaDescription:
        "バッチサイズ変更画像、jpg、png、jpegなどのさまざまな形式をサポートしています。比率またはピクセルに応じて一度に1000を超える画像のサイズを変更し、ソフトウェアをダウンロードする必要はありません。画像のサイズをオンラインで無料で調整します",
      previewTitle: "ファイルの種類",
      reUpload: "再アップロード",
      addPictures: "画像を追加",
      downloadAll: "すべてダウンロード(zip)",
      failedToLoad: "読み込みに失敗しました",
      size: "サイズ",
      dimensions: "寸法",
      afterAdjustment: "調整後",
      state: "状態",
      proportionalScaling: "比例スケーリング",
      specifyDimensions: "寸法を指定",
      width: "幅",
      height: "高さ",
      unSelectAlert: "まだ画像を選択していません。",
      loadingText:
        "ヘイ！ローカルで画像を調整中、バックエンドへのアップロードはありません～プライバシーを確保し、安全で速い！",
      unResize: "処理待ち",
      logoImgAlt: "バルク画像リサイザー logo",
      faqs: [
        {
          question:
            "Q: この無料のオンラインツールを使って、一度に複数の画像のサイズを変更する方法は？",
          answers: [
            "上部の点線のボックスにマウスを移動し、その内部の任意の場所をクリックしてファイル選択ダイアログを開きます。変更したい写真や画像をバッチで選択するか、画像を直接点線のボックスにドラッグアンドドロップできます。この方法で、画像のサイズ変更を無料で簡単に利用できます。",
            "ニーズに応じて、比例的にスケーリングするか、ピクセル値を直接指定するかを選択できます。比例的にスケーリングする場合は、パーセンテージを直接入力します。ピクセル値を指定する場合は、対応する入力ボックスに幅と高さを入力します。これにより、画像のサイズ変更が無料のオンラインプロセスが簡単でユーザーフレンドリーになります。",
            <>
              右端の<strong>サイズ変更開始</strong>
              ボタンをクリックして、すぐに期待通りの画像を生成します。これにより、複数の画像のサイズをすばやく調整できます。
            </>,
            <>
              最後に、右上の<strong>すべてダウンロード</strong>
              ボタンをクリックして、サイズ変更された画像をデバイスに保存します。
            </>,
          ],
        },
        {
          question:
            "Q: お使いのツールはどのような画像形式をサポートしていますか？",
          answers: [
            "当社のツールは、JPG、JPEG、PNG、SVB、GIF、WebPなどの一般的な画像形式を幅広くサポートしています。",
            "ファイルタイプに関係なく、当社のツールを使用して一度に複数の画像のサイズを簡単に変更できます。画像をアップロードする前に、ファイル形式を確認して、当社のバルク画像サイズ変更ツールとの互換性を確保してください。",
          ],
        },
        // その他の日本語の質問と回答
      ],
      // ...add other texts that need translation
    },
  },
  // 德语：
  de: {
    translation: {
      upload: "①Hochladen",
      resizeImage: "Bilder in Stapeln skalieren",
      selectLocalImage: "Lokales Bild auswählen",
      dragAndDrop:
        "Ziehen Sie Bilddateien hierher oder klicken Sie auf die Schaltfläche unten 『Lokales Bild auswählen』",
      imageSizeSupport:
        "Verwenden Sie unseren kostenlosen Online-Bildgrößenänderer, um einzelne oder mehrere Bilder gleichzeitig in verschiedenen Formaten wie jpg, png, jpeg, svg, webp und gif zu skalieren. Passen Sie die Bildgröße einfach für die Stapelbildgrößenänderung an.",
      history: "Verlauf",
      CustomizeSizeTitle: "②Größe anpassen",
      StartResizeTitle: "Größenänderung starten",
      webTitle: "Bildgrößenänderer",
      metaDescription:
        "Bilder in Stapeln skalieren, unterstützt verschiedene Formate wie jpg, png, jpeg usw. Skalieren Sie mehr als 1000 Bilder gleichzeitig nach Verhältnis oder Pixeln, keine Software zum Herunterladen erforderlich, passen Sie die Bildgröße online kostenlos an",
      previewTitle: "Dateityp",
      reUpload: "Erneut hochladen",
      addPictures: "Bilder hinzufügen",
      downloadAll: "Alles herunterladen (zip)",
      failedToLoad: "Fehler beim Laden",
      size: "Größe",
      dimensions: "Abm",
      afterAdjustment: "Nach Anp",
      state: "Zustand",
      proportionalScaling: "Proportionale Skalierung",
      specifyDimensions: "Abmessungen angeben",
      width: "Breite",
      height: "Höhe",
      unSelectAlert: "Sie haben noch kein Bild ausgewählt.",
      loadingText:
        "Hey! Bilder lokal anpassen, kein Backend-Upload~ Datenschutz gewährleisten, sicher und schnell!",
      unResize: "In Bearbeitung",
      logoImgAlt: "Bulk-Bildgrößenänderer logo",
      faqs: [
        {
          question:
            "Q: Wie benutzt man dieses kostenlose Online-Tool, um mehrere Bilder gleichzeitig in der Größe zu ändern?",
          answers: [
            "Bewegen Sie Ihre Maus über das gestrichelte Feld oben und klicken Sie irgendwo darauf, um einen Dateiauswahldialog zu öffnen. Sie können entweder die Fotos oder Bilder auswählen, die Sie ändern möchten, oder die Bilder direkt in das gestrichelte Feld ziehen und ablegen. Auf diese Weise können Sie die Funktion zum kostenlosen Ändern der Bildgröße einfach nutzen.",
            "Je nach Bedarf können Sie proportional skalieren oder Pixelwerte direkt angeben. Wenn Sie proportional skalieren möchten, geben Sie den Prozentsatz direkt ein; wenn Sie Pixelwerte angeben möchten, füllen Sie die Breite und Höhe in die entsprechenden Eingabefelder aus. Dies macht den kostenlosen Online-Prozess zum Ändern der Bildgröße einfach und benutzerfreundlich.",
            <>
              Klicken Sie auf die Schaltfläche{" "}
              <strong>Größe ändern starten</strong> ganz rechts, um sofort
              Bilder zu generieren, die Ihren Erwartungen entsprechen. Dadurch
              können Sie die Größe mehrerer Bilder schnell anpassen.
            </>,
            <>
              Klicken Sie abschließend auf die Schaltfläche{" "}
              <strong>Alle herunterladen</strong> in der oberen rechten Ecke, um
              die geänderten Bilder auf Ihrem Gerät zu speichern.
            </>,
          ],
        },
        {
          question: "Q: Welche Bildformate unterstützt Ihr Tool?",
          answers: [
            "Unser Tool unterstützt eine breite Palette von gängigen Bildformaten, einschließlich JPG, JPEG, PNG, SVB, GIF und WebP.",
            "Mit unserem Tool können Sie problemlos mehrere Bilder gleichzeitig in der Größe ändern, unabhängig von den Dateitypen. Stellen Sie sicher, dass Sie das Dateiformat überprüfen, bevor Sie Ihre Bilder hochladen, um die Kompatibilität mit unserem Bulk-Bildgrößenänderungstool sicherzustellen.",
          ],
        },
        // Weitere Fragen und Antworten auf Deutsch
      ],
      // ...add other texts that need translation
    },
  },
  // 法语：
  fr: {
    translation: {
      upload: "①Télécharger",
      resizeImage: "Redimensionner les images par lots",
      selectLocalImage: "Sélectionner une image locale",
      dragAndDrop:
        "Faites glisser et déposez des fichiers image ici ou cliquez sur le bouton ci-dessous 『Sélectionner une image locale』",
      imageSizeSupport:
        "Redimensionnez une ou plusieurs images en même temps à l'aide de notre redimensionneur d'images en ligne gratuit, prenant en charge divers formats tels que jpg, png, jpeg, svg, webp et gif. Ajustez facilement la taille des images pour le redimensionnement des images par lots.",
      history: "Historique",
      CustomizeSizeTitle: "②Personnaliser la taille",
      StartResizeTitle: "Commencer le redimensionnement",
      webTitle: "Redimensionneur d'images",
      metaDescription:
        "Redimensionner les images par lots, prendre en charge divers formats tels que jpg, png, jpeg, etc. Redimensionner plus de 1000 images à la fois en fonction du rapport ou des pixels, pas besoin de télécharger de logiciel, ajuster la taille de l'image en ligne gratuitement",
      previewTitle: "Type de fichier",
      reUpload: "Télécharger à nouveau",
      addPictures: "Ajouter des images",
      downloadAll: "Télécharger tout (zip)",
      failedToLoad: "Échec du chargement",
      size: "Taille",
      dimensions: "Dim",
      afterAdjustment: "Post-ajustement",
      state: "État",
      proportionalScaling: "Mise à l'échelle proportionnelle",
      specifyDimensions: "Spécifier les dimensions",
      width: "largeur",
      height: "hauteur",
      unSelectAlert: "Vous n'avez pas encore sélectionné d'image.",
      loadingText:
        "Salut ! Ajustement des images localement, sans téléchargement sur le backend~ Assurer la confidentialité, sûr et rapide !",
      unResize: "En traitement",
      logoImgAlt: "redimensionneur d'images en vrac logo",
      faqs: [
        {
          question:
            "Q: Comment utiliser cet outil en ligne gratuit pour redimensionner plusieurs images en même temps?",
          answers: [
            "Déplacez votre souris sur la zone en pointillés en haut et cliquez n'importe où à l'intérieur pour ouvrir une boîte de dialogue de sélection de fichier. Vous pouvez sélectionner par lots les photos ou images que vous souhaitez modifier ou faire glisser et déposer directement des images dans la zone en pointillés. De cette façon, vous pouvez facilement utiliser la fonction de redimensionnement d'image gratuite.",
            "Selon vos besoins, vous pouvez choisir de mettre à l'échelle proportionnellement ou de spécifier directement les valeurs de pixels. Si vous choisissez de mettre à l'échelle proportionnellement, entrez directement le pourcentage; si vous choisissez de spécifier les valeurs de pixels, remplissez la largeur et la hauteur dans les cases d'entrée correspondantes. Cela rend le processus de redimensionnement d'image en ligne gratuit simple et convivial.",
            <>
              Cliquez sur le bouton{" "}
              <strong>Commencer le redimensionnement</strong> à l'extrême droite
              pour générer immédiatement des images qui répondent à vos
              attentes. Cela vous permet d'ajuster rapidement la taille de
              plusieurs images.
            </>,
            <>
              Enfin, cliquez sur le bouton <strong>Télécharger tout</strong>{" "}
              dans le coin supérieur droit pour enregistrer les images
              redimensionnées sur votre appareil.
            </>,
          ],
        },
        {
          question: "Q: Quels formats d'image votre outil prend-il en charge?",
          answers: [
            "Notre outil prend en charge une large gamme de formats d'image populaires, y compris JPG, JPEG, PNG, SVB, GIF et WebP.",
            "Vous pouvez facilement redimensionner plusieurs images en même temps à l'aide de notre outil, quel que soit le type de fichier. Assurez-vous de vérifier le format du fichier avant de télécharger vos images pour garantir leur compatibilité avec notre outil de redimensionnement d'image en vrac.",
          ],
        },

        // Autres questions et réponses en français
      ],
      // ...add other texts that need translation
    },
  },
  // 韩语
  ko: {
    translation: {
      upload: "①업로드",
      resizeImage: "배치 크기 조정 이미지",
      selectLocalImage: "로컬 이미지 선택",
      dragAndDrop:
        "이미지 파일을 여기로 드래그 앤 드롭하거나 아래 버튼을 클릭하세요 『로컬 이미지 선택』",
      imageSizeSupport:
        "jpg, png, jpeg, svg, webp 및 gif와 같은 다양한 형식을 지원하는 무료 온라인 대량 이미지 크기 조정기를 사용하여 한 번에 하나 이상의 이미지 크기를 조정하세요. 배치 그림 크기 조정을 위해 이미지 크기를 쉽게 조정하세요.",
      history: "역사",
      CustomizeSizeTitle: "②크기 사용자 정의",
      StartResizeTitle: "크기 조정 시작",
      webTitle: "대량 이미지 크기 조정기",
      metaDescription:
        "일괄 크기 조정 이미지, jpg, png, jpeg 등과 같은 다양한 형식을 지원합니다. 비율 또는 픽셀에 따라 한 번에 1000개 이상의 이미지 크기를 조정하고 소프트웨어를 다운로드할 필요가 없으며 온라인에서 이미지 크기를 무료로 조정합니다",
      previewTitle: "파일 유형",
      reUpload: "다시 업로드",
      addPictures: "사진 추가",
      downloadAll: "모두 다운로드 (zip)",
      failedToLoad: "로드 실패",
      size: "크기",
      dimensions: "치수",
      afterAdjustment: "조정 후",
      state: "상태",
      proportionalScaling: "비례 스케일링",
      specifyDimensions: "치수 지정",
      width: "너비",
      height: "높이",
      unSelectAlert: "아직 이미지를 선택하지 않았습니다.",
      loadingText:
        "헤이! 로컬에서 이미지 조정 중, 백엔드 업로드 없음~ 개인 정보 보호, 안전하고 빠르게!",
      unResize: "처리 대기 중",
      logoImgAlt: "대량 이미지 크기 조정기 logo",
      faqs: [
        {
          question:
            "Q: 한 번에 여러 이미지 크기를 조정하기 위해 이 무료 온라인 도구를 어떻게 사용하나요?",
          answers: [
            "맨 위의 점선 상자 위로 마우스를 이동하고 내부의 아무 곳이나 클릭하여 파일 선택 대화 상자를 엽니다. 수정하려는 사진이나 이미지를 일괄 선택하거나 이미지를 직접 점선 상자로 드래그 앤 드롭 할 수 있습니다. 이렇게 하면 이미지 크기 조정 무료 기능을 쉽게 사용할 수 있습니다.",
            "필요에 따라 비례적으로 크기를 조정하거나 픽셀 값을 직접 지정할 수 있습니다. 비례 크기 조정을 선택하는 경우 비율을 직접 입력하고 픽셀 값을 지정하려면 해당 입력 상자에 너비와 높이를 입력하십시오. 이렇게하면 이미지 크기 조정 무료 온라인 프로세스가 간단하고 사용자 친화적이 됩니다.",
            <>
              오른쪽 끝에있는 <strong>크기 조정 시작</strong> 버튼을 클릭하여
              즉시 기대에 부합하는 이미지를 생성하십시오. 이렇게하면 여러 이미지
              크기를 빠르게 조정할 수 있습니다.
            </>,
            <>
              마지막으로 오른쪽 상단의 <strong>모두 다운로드</strong> 버튼을
              클릭하여 크기가 조정된 이미지를 기기에 저장하십시오.
            </>,
          ],
        },
        {
          question: "Q: 귀하의 도구는 어떤 이미지 형식을 지원합니까?",
          answers: [
            "저희 도구는 JPG, JPEG, PNG, SVB, GIF, WebP 등 인기 있는 이미지 형식의 광범위한 범위를 지원합니다.",
            "파일 유형에 관계없이 저희 도구를 사용하여 한 번에 여러 이미지의 크기를 쉽게 조정할 수 있습니다. 이미지를 업로드하기 전에 파일 형식을 확인하여 대량 이미지 크기 조정 도구와의 호환성을 확인하십시오.",
          ],
        },
        // 한국어로 된 다른 질문 및 답변
      ],
      // ...add other texts that need translation
    },
  },
};

i18n.use(initReactI18next).init({
  resources,
  lng: "en", // 默认语言，可以根据需要更改
  keySeparator: false,
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
