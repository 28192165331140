import React from "react";
import "./FAQ.css";

const FAQ = ({ question, answers }) => {
  return (
    <div className="faq">
      <details>
        <summary className="faq-question">{question} </summary>
        {answers.map((answer, index) => (
          <ul>
            <li className="faq-answer" key={index}>
              {answer}
            </li>
          </ul>
        ))}
      </details>
    </div>
  );
};

export default FAQ;
